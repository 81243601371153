import {find, findAll} from '@elements/dom-utils';
import KeenSlider from 'keen-slider';


const defaultSelectors = {
    base: 'room-plan-slider-area__images',
    up: '.js-room-plan-slider__up',
    down: '.js-room-plan-slider__down',
    indicator: '.js-room-plan-slider-area__indicator',
};

export function init(selectors = defaultSelectors) {

    const sliderEl = document.getElementById(selectors.base);

    function navigation(slider) {
        let indicator, arrowUp, arrowDown

        function markup(remove) {
            if (remove) {
                removeElement(indicator)
                removeElement(arrowUp)
                removeElement(arrowDown)
                return
            }

            indicator = findAll(selectors.indicator);

            arrowUp = find(selectors.up);
            arrowUp.addEventListener("click", () => {
                var slide = slider.track.details.rel;
                indicator[slide].classList.remove('indicator--selected');
                indicator[slide - 1].classList.add('indicator--selected');
                slider.prev();
            })

            arrowDown = find(selectors.down);
            arrowDown.addEventListener("click", () => {
                var slide = slider.track.details.rel;
                indicator[slide].classList.remove('indicator--selected');
                indicator[slide + 1].classList.add('indicator--selected');
                slider.next()
            })
        }

        function removeElement(elment) {
            elment.parentNode.removeChild(elment)
        }


        function updateClasses() {
            var slide = slider.track.details.rel

            slide === 0
                ? arrowUp.setAttribute('disabled', 'true')
                : arrowUp.removeAttribute('disabled')
            slide === slider.track.details.slides.length - 1
                ? arrowDown.setAttribute('disabled', 'true')
                : arrowDown.removeAttribute('disabled')
        }

        slider.on("created", () => {
            markup()
            updateClasses()
        })
        slider.on("optionsChanged", () => {
            markup(true)
            markup()
            updateClasses()
        })
        slider.on("slideChanged", () => {
            updateClasses()
        })
        slider.on("destroyed", () => {
            markup(true)
        })
    }

    if (sliderEl) {
        var slider = new KeenSlider(
            sliderEl,
            {
                drag: false,
                defaultAnimation: {
                    duration: 3500,
                },
                vertical: true,
                // breakpoints: {
                //     '(min-width: 768px)': {
                //         vertical: true,
                //     },
                // },
                slides: {
                    spacing: 35,
                },

            },
            [navigation]
        )
    }
}