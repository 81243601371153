import {onFind} from "@elements/init-modules-in-scope";
import {findIn, find, hasClass, findAllIn, removeClass, on} from '@elements/dom-utils';
import lightGallery from 'lightgallery';
import lgMediumZoom from 'lightgallery/plugins/mediumZoom';

const defaultSelectors = {
    base: '.js-room-plan-lightbox',
};

export function init(selectors = defaultSelectors) {
    onFind(selectors.base, function (baseElement) {

        const dynamicGallery = lightGallery(baseElement, {
            plugins: [lgMediumZoom],
            dynamic: true,
            backgroundColor: '#868686',
            showCloseIcon: true,
            download: false,
            controls: false,
            counter: false,
            hideScrollbar: true,
            enableDrag: false,
            dynamicEl: [
                {
                    src: 'https://dev-alpbachtal2022.elements.zone/development/393207/image-thumb__393207__room-plan-map/CCA_Raumplan_blanko.webp',
                    subHtml: '<button class="btn btn-primary btn-sm--mobile room-plan-lightbox__close-btn"><span class="icon icon-x" aria-label="open room plan"></span><span>Raumplan schließen</span></button>',
                }]
        });

        baseElement.addEventListener('click', () => {
            dynamicGallery.openGallery(0);
        })
    });
}