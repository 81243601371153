import {find} from '@elements/dom-utils';
import KeenSlider from 'keen-slider';

const defaultSelectors = {
    base: 'room-detail-hero-area__video-slider',
    container: '.js-room-detail__hero',
    nav: '.main-navbar-nav',
    body: '.cca',

};

export function init(selectors = defaultSelectors) {

    const sliderEl = document.getElementById(selectors.base);
    let container = find(selectors.container);
    let body = find(selectors.body);
    let offset = find(selectors.nav).offsetHeight;
    let scrollDirectionDown = true;

    function navigation(slider) {
        let wheelDelay = 0;

        sliderEl.addEventListener("wheel", (e) => {
            const direction =
                Math.abs(e.deltaX) > Math.abs(e.deltaY) ? e.deltaX : e.deltaY;

            setTimeout(() => {

                if (container.getBoundingClientRect().top <= 0 && direction > 0) {
                    slider.next();
                }

                if (container.getBoundingClientRect().top >= 0 && direction < 0) {
                    slider.prev();
                }
            }, wheelDelay);

        });
    }

    function scroll(slider) {

        document.onwheel = function (e) {
            let deltaY = e.deltaY;

            if (deltaY < 0) {
                scrollDirectionDown = false;
            } else if (deltaY > 0) {
                scrollDirectionDown = true;
            }

            if (container.getBoundingClientRect().top <= offset && scrollDirectionDown && !(slider.track.details.abs === slider.track.details.maxIdx) && window.scrollY <= container.getBoundingClientRect().bottom) {
                container.classList.add('room-detail__hero--freezed');
                body.classList.add('body-cca--freezed');
            } else if (container.getBoundingClientRect().top >= -offset && !scrollDirectionDown && !(slider.track.details.abs === slider.track.details.minIdx)) {
                container.classList.add('room-detail__hero--freezed');
                body.classList.add('body-cca--freezed');
            } else {
                container.classList.remove('room-detail__hero--freezed');
                body.classList.remove('body-cca--freezed');
            }
        }
    }

    if (sliderEl) {
        let numberSlides = sliderEl.childElementCount;

        var slider = new KeenSlider(
            sliderEl,
            {
                loop: false,
                draggable: true,
                rubberband: false,
                vertical: true,
                initial: -1,
                range: {
                    'min': -1,
                    'max': numberSlides,
                },
                defaultAnimation: {
                    duration: 4500,
                    // easing: function(t) {
                    //     return  1 - Math.pow(-2 * t + 2, 2) / 6;
                    // },
                },
            }, [navigation, scroll]
        )
    }
}