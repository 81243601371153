"use strict";
import {isElementInViewport} from '@elements/viewport-utils';
import {add} from '@elements/scroll-animations';
import {onFind} from "@elements/init-modules-in-scope";
import {findAll, find, findAllIn} from "@elements/dom-utils";

export function initInScope($scope) {
    if (matchMedia('(max-width: 767px)').matches) {
        return;
    }

    onFind('.js-parallax-area__content', function (baseElement) {
        let teaser = findAllIn('.js-parallax-area__content-teaser', baseElement);
        teaser[teaser.length - 1].classList.add('hasGradient');
    });


    add(
        findAll('.js-parallax'),
        calculateAnimationProgress,
        setAnimationProgress,
    );
}

// gets element -> returns number between 0 and 1
function calculateAnimationProgress(element) {
    if (element) {

        let content = find('.js-parallax-area__content');

        element.style.height = content.clientHeight - element.getAttribute('data-parallax-value') + 'px';

        const start = window.innerHeight / 8 * 2;
        const end = -element.getBoundingClientRect().bottom;

        return 1 - Math.max(
            Math.min(
                (element.getBoundingClientRect().top - end) / (start - end),
                1
            ),
            0
        );
    }
}

// gets element & progress (number between 0 - 1) -> sets style of element
function setAnimationProgress(element, progress) {
    if (element) {
        let parallaxValue = element.getAttribute('data-parallax-value') || 20;
        let parallaxProgress = progress * parallaxValue;

        element.style.transform = "translateY(" + parallaxProgress + "px)";
    }
}
